import { Worker } from 'entrypoint';

import {
  EditWorkerDialog,
  CreateWorkerDialog,
  ToggleWorkerStatusDialog,
} from './Dialogs';

import UserIcon from '@/components/Icons/UserIcon';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/Table';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/Tabs';
import { handleButtonClick } from '@/utils/analyticsUtils';

type WorkersTableProps = {
  workers: Worker[];
};

export const WorkersTable = ({ workers }: WorkersTableProps) => {
  return (
    <>
      <Tabs defaultValue="active">
        <div className="flex justify-between">
          <TabsList className="h-8 w-1/6">
            <TabsTrigger
              className="h-full w-1/2"
              value="active"
              onClick={() => handleButtonClick('有効')}
            >
              有効
            </TabsTrigger>
            <TabsTrigger
              className="h-full w-1/2"
              value="inactive"
              onClick={() => handleButtonClick('無効')}
            >
              無効
            </TabsTrigger>
          </TabsList>
          <CreateWorkerDialog />
        </div>
        <TabsContent value="active">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="px-4 py-2 text-left">表示名</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {workers
                .filter((worker) => worker.is_active)
                .map((worker) => (
                  <TableRow key={worker.id}>
                    <TableCell className="flex items-center px-4 py-3">
                      <span className="mr-4">
                        <UserIcon />
                      </span>
                      <span className="font-medium">{worker.name}</span>
                    </TableCell>
                    <TableCell className="px-4 py-3 text-right">
                      <EditWorkerDialog worker={worker} />
                      <ToggleWorkerStatusDialog worker={worker} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TabsContent>
        <TabsContent value="inactive">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="px-4 py-2 text-left">表示名</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {workers
                .filter((worker) => !worker.is_active)
                .map((worker) => (
                  <TableRow key={worker.id}>
                    <TableCell className="flex items-center px-4 py-3">
                      <span className="mr-4">
                        <UserIcon />
                      </span>
                      <span className="font-medium">{worker.name}</span>
                    </TableCell>
                    <TableCell className="px-4 py-3 text-right">
                      <ToggleWorkerStatusDialog worker={worker} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TabsContent>
      </Tabs>
    </>
  );
};
