import { useAuth } from 'entrypoint';
import { useState } from 'react';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';

import appLogoSvg from '../../assets/app-logo2.svg';
import { AppVersion } from '../AppVersion';
import {
  PanelRightOpen,
  PanelLeftOpen,
  ArrowDownward,
  ArrowSmallLine,
} from '../Icons';

import { NavigationButton } from './NavigationButton';
import { Profile } from './Profile';

import { useSidebar } from '@/context/SidebarContext';
import { useOrganizationQuery } from '@/hooks/api/useOrganization';
import { useViewsQuery } from '@/hooks/api/useViews';
import { handleButtonClick } from '@/utils/analyticsUtils';

interface NavigationProps {
  isSettingMenuOpen: boolean;
  setIsSettingMenuOpen: (isOpen: boolean) => void;
}

export const Navigation = ({
  isSettingMenuOpen,
  setIsSettingMenuOpen,
}: NavigationProps) => {
  const { signOut, currentUser } = useAuth();
  const location = useLocation();
  const organizationConfig = useOrganizationQuery();
  const views = useViewsQuery();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const handleClickLogout = async () => {
    await signOut();
  };
  const { sidebarWidth, setSidebarWidth } = useSidebar();
  const [isNavigationTabOpen, setIsNavigationTabOpen] = useState(true);

  const toggleSidebar = () => {
    setIsNavigationTabOpen(!isNavigationTabOpen);
    setSidebarWidth(isNavigationTabOpen ? '70px' : '250px');
  };
  const toggleSettingMenu = () => {
    setIsSettingMenuOpen(!isSettingMenuOpen);
  };

  return (
    <nav
      style={{ width: sidebarWidth }}
      className={
        'sticky top-0 flex h-full min-h-screen flex-col border-r border-border'
      }
    >
      {isNavigationTabOpen ? (
        <>
          <div className="flex-grow gap-6">
            <div className="flex w-full items-center gap-4 border-b border-border py-6 pl-4">
              <a href={'/'} onClick={() => handleButtonClick('CountAIロゴ')}>
                <img src={appLogoSvg} alt="StatHack CountAI logo" width={128} />
              </a>
              <button
                onClick={() => {
                  handleButtonClick('PanelRightOpen');
                  toggleSidebar();
                }}
                className="ml-4"
              >
                <PanelRightOpen />
              </button>
            </div>
            {organizationConfig.data?.display_name && (
              <div className="flex px-4 py-3 font-bold">
                <button
                  onClick={() => {
                    handleButtonClick(
                      isSettingMenuOpen ? 'ArrowDownward' : 'ArrowSmallLine',
                    );
                    toggleSettingMenu();
                  }}
                  className="relative bottom-0.5"
                >
                  {isSettingMenuOpen ? (
                    <ArrowDownward />
                  ) : (
                    <ArrowSmallLine
                      className="stroke-current"
                      width={32}
                      height={32}
                      strokeWidth={1.5}
                    />
                  )}
                </button>
                {organizationConfig.data.display_name}
              </div>
            )}
            {isSettingMenuOpen && (
              <ul className="flex flex-col gap-2  border-b border-border px-4 pb-2">
                <li>
                  <NavigationButton
                    onClick={() => {
                      handleButtonClick('作業者一覧');
                      navigate('/workers', { state: { isSettingMenuOpen } });
                    }}
                    text="作業者一覧"
                    state={
                      location.pathname === '/workers' ? 'selected' : 'default'
                    }
                  />
                </li>
              </ul>
            )}
            {views.data && (
              <ul className="flex flex-col gap-2 px-4">
                {views.data.map((view) => (
                  <li key={view.id}>
                    <NavigationButton
                      onClick={() => {
                        handleButtonClick(view.title);
                        location.pathname.includes('/workers')
                          ? navigate(`/?viewId=${view.id}`, {
                              state: { isSettingMenuOpen },
                            })
                          : setSearchParams({ viewId: view.id });
                      }}
                      text={view.title}
                      state={
                        location.pathname === '/' &&
                        searchParams.has('viewId', view.id)
                          ? 'selected'
                          : 'default'
                      }
                    />
                  </li>
                ))}
              </ul>
            )}
          </div>
          <ul className="flex flex-col gap-2 border-t border-border px-4 py-2">
            <li>
              <NavigationButton
                onClick={() => {
                  window.open(
                    'https://docs.google.com/presentation/d/1ZsTWWXApD3CLpadqwnXUljkbE4xMuEpqPAoJ1m6GzvA/edit#slide=id.p',
                    '_blank',
                    'noopener,noreferrer',
                  );
                }}
                text="ヘルプ"
              />
            </li>
          </ul>
          <div className="flex flex-col gap-4 p-4">
            <button
              className="transision-colors rounded p-2 text-left text-cool-grey-400 hover:bg-cool-grey-100"
              onClick={() => {
                handleButtonClick('ログアウト');
                handleClickLogout();
              }}
            >
              ログアウト
            </button>
            <Profile
              name={currentUser?.display_name ?? ''}
              id={currentUser?.name ?? ''}
            />
            <AppVersion />
          </div>
        </>
      ) : (
        <div className="mt-8 flex items-center justify-center">
          <button
            onClick={() => {
              handleButtonClick('PanelLeftOpen');
              toggleSidebar();
            }}
            className="text-2xl"
          >
            <PanelLeftOpen />
          </button>
        </div>
      )}
    </nav>
  );
};
